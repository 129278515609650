import KeyboardDoubleArrowUpOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowUpOutlined';
import './ScrollButtonStyles.scss';
import 'animate.css';


const ScrollButton = () => {
    const scrolled = () => {
        const btn = document.getElementsByClassName('bouton')[0]
        if(window.scrollY > 500){
            btn.style.display = 'block'
        } else{
            btn.style.display = 'none'
        }
    }
    window.addEventListener('scroll', scrolled);

    return(

        
        <div className='bouton animate__animated animate__bounceInUp'> 
         <a href='#top'><KeyboardDoubleArrowUpOutlinedIcon sx= {{fontSize: 20, 
            color: "#fff",
            "@media(max-width:1200px)":{
                fontSize: 19,
            } ,
            "@media(max-width:992px)":{
                fontSize: 18,
            },
            "@media(max-width:768px)":{
                fontSize: 17,
            },
            "@media(max-width:570px)":{
                fontSize: 15,
            }
            }} className="fleche"/> </a>
        </div>
      
    );
};
export default ScrollButton
