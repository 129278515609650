import React from "react";
import { useState, useEffect, useRef } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { makeStyles } from "@mui/styles";
// import { alpha } from "@mui/system";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { Container, Grid, TextField, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import { toast } from "material-react-toastify";
import emailjs from "emailjs-com";
import { useTranslation } from "react-i18next";
import theme from "../../../../Theme/theme";
import './contactContent.scss'
import RecaptchaForm from "./Recaptcha";

const useStyles = makeStyles({
  Icones: {
    backgroundColor: "#2896af",
    color: theme.common.offWhite,
    padding: "10px",
    borderRadius: "100px",
  },
});

const CssTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'var(--input_borderColor)',
      color: 'var(--input_borderColor)'
    },

    '&:hover fieldset': {
      borderColor: 'var(--input_hover)',
    },
  }
})

const Items = ({ Icon, title, description, classes }) => {
  return (
    <Box display="flex" direction="row" sx={{ marginBottom: theme.spacing(3) }}>
      <Box sx={{ marginRight: "20px" }}>
        <Box className={classes.Icones}>
          <Icon />
        </Box>
      </Box>
      <Box>
        <Typography
          sx={{ fontSize: "16px", fontWeight: 600, marginBottom: "5px" }}
        >
          {title}
        </Typography>
        <Typography sx={{ fontSize: "14px" }}>{description}</Typography>
      </Box>
    </Box>
  );
};

const ContactContent = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const recaptchaRef = useRef();
  const [token, setToken] = useState("");

  const validationSchema = yup.object({
    fullname: yup
      .string()
      .required(t("requireContact")),

    email: yup
      .string()
      .email(t("emailErrorContact"))
      .required(t("requireContact")),

    phone: yup
      .string() //TODO faire le regex du numéro de téléphone
      .required(t("requireContact")),

    subject: yup
      .string()
      .required(t("requireContact")),

    message: yup
      .string()
      .required(t("requireContact")),

    recaptcha: yup
      .string()
      .required(t("requireContact"))
  });

  const formik = useFormik({
    initialValues: {
      fullname: '',
      email: '',
      phone: '',
      subject: '',
      message: '',
      recaptcha: ''
    },
    validationSchema,
    onSubmit: async (values) => {
      // console.log("values",values);
      setIsLoading(true);
      const params = {
        user_name: values.fullname,
        to_name: "MesinTech",
        subject: values.subject,
        contact_number: values.phone,
        message: values.message,
        user_email: values.email,
      };

      emailjs
        .send(
          "service_hhwwvhy",
          "template_h1krlai",
          params,
          "zCmPySr36jjnWxFZI"
        )
        .then(
          function (response) {
            toast.success("Le formulaire à bien été soumis");
            console.log(recaptchaRef);
            recaptchaRef.current.reset();
            formik.handleReset();
            setIsLoading(false);
          },
          function (error) {
            toast.error("Echec d'envoie, veillez réessayer");
            console.log(recaptchaRef);
            recaptchaRef.current.reset();
            formik.handleReset();
            setIsLoading(false);
          }
        );
    },
  });
  
  useEffect(() =>  {
    formik.setFieldValue('recaptcha', token);
  }, [setToken, token]);

  return (
    <div style={{ color: "var(--color_problem-vision)" }}>
      <Container>
        <Grid container spacing={10}>
            <Grid item container xs={12} md={8} spacing={2}>
              <Grid item xs={12} sm={6}>
                <CssTextField
                  InputProps={{ className: "textfield" }}
                  InputLabelProps={{
                    className:
                      formik.touched.fullname && formik.errors.fullname
                        ? "textfield_label_red"
                        : "textfield_label",
                  }}
                  label={t("nameContact")}
                  variant="outlined"
                  placeholder={t("nameContact_placeholder")}
                  fullWidth
                  required
                  name="fullname"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.fullname}
                  error={!!formik.touched.fullname && !!formik.errors.fullname}
                  helperText={formik.touched.fullname && formik.errors.fullname}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CssTextField
                  InputLabelProps={{
                    className:
                      formik.touched.email && formik.errors.email
                        ? "textfield_label_red"
                        : "textfield_label",
                  }}
                  InputProps={{ className: "textfield" }}
                  label={t("emailContact")}
                  variant="outlined"
                  placeholder={t("emailContact_placeholder")}
                  fullWidth
                  required
                  name="email"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  error={!!formik.touched.email && !!formik.errors.email}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CssTextField
                  InputLabelProps={{
                    className:
                      formik.touched.phone && formik.errors.phone
                        ? "textfield_label_red"
                        : "textfield_label",
                  }}
                  InputProps={{ className: "textfield" }}
                  label={t("numberContact")}
                  variant="outlined"
                  placeholder={t("numberContact_placeholder")}
                  fullWidth
                  required
                  name="phone"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.phone}
                  error={!!formik.touched.phone && !!formik.errors.phone}
                  helperText={formik.touched.phone && formik.errors.phone}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CssTextField
                  InputLabelProps={{
                    className:
                      formik.touched.subject && formik.errors.subject
                        ? "textfield_label_red"
                        : "textfield_label",
                  }}
                  InputProps={{ className: "textfield" }}
                  label={t("subjectContact")}
                  variant="outlined"
                  placeholder={t("subjectContact_placeholder")}
                  fullWidth
                  required
                  name="subject"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.subject}
                  error={!!formik.touched.subject && !!formik.errors.subject}
                  helperText={formik.touched.subject && formik.errors.subject}
                />
              </Grid>
              <Grid item xs={12}>
                <CssTextField
                  InputProps={{ className: "textfield" }}
                  InputLabelProps={{
                    className:
                      formik.touched.message && formik.errors.message
                        ? "textfield_label_red"
                        : "textfield_label",
                  }}
                  label={t("messageContact")}
                  multiline
                  placeholder={t("messageContact_placeholder")}
                  fullWidth
                  required
                  rows={6}
                  name="message"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.message}
                  error={!!formik.touched.message && formik.errors.message}
                  helperText={formik.touched.message && formik.errors.message}
                />
              </Grid>
              <Grid item>
                <Box marginBottom="15px">
                  <RecaptchaForm name="recaptcha" ref={recaptchaRef} setToken={setToken}/>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                  <LoadingButton
                    disableElevation
                    loading={isLoading}
                    variant="outlined"
                    type="button"
                    onClick={formik.handleSubmit}
                    sx={{
                      color: "#ffffff",
                      backgroundColor: "#2896af",
                      borderColor: "#2896af",
                      transition: "0.2s linear",
                      width: "90%",
                      padding: "14px 25px",
                      "&:hover": {
                        // backgroundColor: alpha(theme.palette.primary.main, 0.8),
                        // borderColor: theme.palette.primary.main,
                        borderColor: "var(--theme_outline_dark) ",
                        color: "var(--theme_outline_dark)",
                        fontWeight: "bold",
                        fontSize: "15px",
                        borderRadius: "5px",
                        justifyContent: " center",
                        transition: " all 0.5s ease-in-out",
                        padding: "14px 25px",
                      },
                    }}
                  >
                    {t("buttonContact")}
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>

          <Grid item xs={12} md={4}>
            <Items
              Icon={LocationOnIcon}
              title={t("adressReference")}
              description="Immeuble KAMGA, P8, Douala"
              classes={classes}
            />
            <Items
              Icon={PhoneIcon}
              title={t("phoneReference")}
              description="Mobile: (+237) 656-145-877"
              classes={classes}
            />
            <Items
              Icon={EmailIcon}
              title={t("emailReference")}
              description="info@mesintech.net"
              classes={classes}
            />
          </Grid>
        </Grid>
        <div style={{ height: "500px", width: "100%", margin: "3rem 0rem" }}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31838.080181120542!2d9.709471589694044!3d4.0692520681441176!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x10610d1f837fd73f%3A0xbc6f1a357858535e!2sImmeuble%20KAMGA!5e0!3m2!1sfr!2scm!4v1654696600074!5m2!1sfr!2scm"
            width="100%"
            height="100%"
            style={{ border: 0 }}
            loading="lazy"
          ></iframe>
        </div>
      </Container>
    </div>
  );
};

export default ContactContent;
