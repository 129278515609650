import { useState } from "react";
import React from "react";
import LanguageContext from "./LanguageContext";
import { useTranslation } from "react-i18next";


export default function LanguageContextProvider({ children }) {
  let [activeLanguage, setActiveLanguage] = useState(
    localStorage.getItem("lang") || "Français"
  );
  const { t, i18n } = useTranslation();
  const changeLanguage = (language) => {
    setActiveLanguage(language);
    localStorage.setItem("lang", language);
    i18n.changeLanguage(language);
  };

  return (
    <LanguageContext.Provider value={{ activeLanguage, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
}
