import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import './problemsAndVisions.scss'




const ProblemsAndVisions = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="problems-visions">
      <h1>
        <strong> {t("Why MesinTech")} </strong>
      </h1>
      <div className="row">
        <div className="column" style={{ position: "relative" }}>
          <img className="img-problem"
            src={"/images/Propos/solution.png"}
            alt="Problems and visions image 1"
          />
        </div>
        <div className="column text">
          <h2>{t("why_mesintech_title_left")}</h2>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <p>{t("why_mesintech_text_left")}</p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="column text">
          <h2>{t("why_mesintech_title_right")}</h2>
          <p>{t("why_mesintech_text_right")}</p>
        </div>
        <div
          className="column"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <img className="img-problem"
            src={"/images/Propos/inovation.png"}
            alt="Problems and visions image 2"
          />
        </div>
      </div>
    </div>
  );
};

export default ProblemsAndVisions;
