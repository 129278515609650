import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0096c7",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#0B612D",
      contrastText: "#ffffff",
    },
  },
  common: {
    bodyDefault: "#000000",
    bodyTitle: "#252525",
    bodyText: "#6C757D",
    line: "#DADADA",
    bgcolor: "#F8F9FA",
    offWhite: "#FFFFFF",
    bgtransparent: 'transparent',
  },
  typography: {
    h1: {
      fontSize: "3rem",
      lineHeight: 1.2,
    },
    h2: {
      fontSize: "2rem",
      lineHeight: 1.2,
    },
    h3: {
      fontSize: "1.5rem",
      lineHeight: 1.2,
    },
    h4: {
      fontSize: "1.25rem",
      lineHeight: 1.2,
    },
  },
});

export default theme;
