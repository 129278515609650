import React from "react";
import HeaderProduits from "../components/header/HeaderProduits";
import SectionComponent from "../components/SectionComponent";
import { useTranslation } from "react-i18next";
import Products from "../components/products/Products";
import CarouselList from "../../../components/carousel/carouselList/CarouselList";

const Produits = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <HeaderProduits />
      <div id="product">
        <SectionComponent content={<Products />} />
      </div>
      <CarouselList/>
    </>
  );
};

export default Produits;
