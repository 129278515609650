import { Container } from '@mui/material';
import React from 'react';
import BlogItem from './BlogItem/index';
import './styles.css';

const BlogList = ({ blogs }) => {

  return (
    
    <Container>
      <div className='blogList-wrap'>
        
        {blogs.map((blog, index) => (
            <BlogItem blog={blog} key={index} />
        ))}
      </div>
    </Container>
  );
};

export default BlogList;