import React from "react";
import ReCAPTCHA from "react-google-recaptcha"

// const recaptchaRef = React.createRef();
function verifyCallback(token, props) {
    // console.log(recaptchaRef.current.getValue());
    // recaptchaRef.current.reset();
    if (token) props(token);
}

const  RecaptchaForm = React.forwardRef(({setToken}, ref) => {
    
    return (
        <ReCAPTCHA
          ref={ref}
          style={{ display: "inline-block" }}
          theme="light"
          sitekey="6Ld6tw4eAAAAAC5QjY2uGXxP_bnsyIWh7QLULrDH"
          onChange={(token) => {
            verifyCallback(token, setToken)
          }}
        />
    );

});

export default RecaptchaForm;