import React from "react";
import './service.scss';

const ServiceDetail = ({item}) => {
    return (
      <>
        <div className="col-md-4">
          <div className="main">
            <div className="service" >
              <div className="service-logo">
                <img src={item.image}></img>
              </div>
              <h4>{item.title}</h4>
              <p>{item.contenu}</p>
            </div>
            <div className="shadowOne"></div>
            <div className="shadowTwo"></div>
          </div>
        </div>
      </>
    );
};

export default ServiceDetail;