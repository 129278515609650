import React from "react";
import App from "./App";
import ReactDOM from "react-dom";
import LanguageContextProvider from "./contexts/Language/LanguageContextProvider";
import"./contexts/Language/i18n";
import { ThemeProvider } from "./styles/Theme";




ReactDOM.render(
  <React.StrictMode>
    <LanguageContextProvider>
      <ThemeProvider>
        <App />
      </ThemeProvider>
    </LanguageContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
