export const serviceDetail_en = [
  {
    title: "Web site development",
    image: "images/Services/internet.png",
    contenu:
      "A team of experts for your web projects: we are able to bring your ideas to life with tailor-made creations such as web applications. All our developments follow current standards while remaining vigilant to emerging developments.",
  },
  {
    title: "Mobile Development ",
    image: "images/Services/app-development.png",
    contenu:
      "An expert team to develop Mobile applications that deliver unparalleled business results. We create feature-rich, cost-effective and scalable mobile applications tailored to your business needs using state-of-the-art technology and tools.",
  },
  {
    title: "Desktop Development ",
    image: "images/Services/desktop.png",
    contenu:
      "In the era of the Internet and smartphone revolution, some use cases cannot be replaced entirely by a web or mobile application. Our team of experts is able to assist you in the complete development of desktop applications.",
  },
  {
    title: "Logo/Design",
    image: "images/Services/logo-design.png",
    contenu:
      "Our experts carry out the entire electronic design phase of a product or an embedded electronic system: feasibility study, development of electronic schematics, computer-aided design for the printed circuit board (PCB routing) and the manufacture of the electronic board.",
  },
  {
    id: 5,
    title: "Electronic design",
    image: "images/Services/electronic_icon.png",
    contenu:
      "You need a logo, a flyer or a business card to enhance your company's image; a 3D design or a simple graphic design, our expert team is there for you.",
  },
];