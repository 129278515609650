import React, { Fragment, useState } from "react";
import "./header.scss";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import "animate.css";

const Header = () => {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);

  /**fonction d'ouverture du pop-up de lecture contenant la vidéo */
  const handleClickOpen = () => {
    setOpen(true);
  };

  /**fonction de fermeture du pop-up de lecture contenant la vidéo */
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      <div className="global">
        <div className="header-container">
          <h2>{t("header_description")}</h2>
          <h4>{t("header_sous_description")}</h4>
          <div className="play">
            <NavLink to="/contact" className="contact">
              {t("Contact")}
            </NavLink>

            <NavLink to="/propos" className="play-icon">
              {t("text_button_video")}
            </NavLink>
          </div>
        </div>
        <div className="img-container">
          <img src="images/header/ordi.png"></img>
        </div>
      </div>

      <div className="back-trans2 animate__animated animate__fadeInTopLeft animate__delay-0.2s animate__slower"></div>
      <div className="back-trans animate__animated animate__fadeInBottomRight animate__delay-1s animate__slow"></div>
      <div className="back-trans3 animate__animated animate__fadeInBottomLeft animate__delay-0.7s animate__slow"></div>

      <div className="box">
        <div>
          {" "}
          <img src="images/header/setting.png"></img>{" "}
        </div>
        <div>
          {" "}
          <img src="images/header/ecran.png"></img>{" "}
        </div>
        <div>
          {" "}
          <img src="images/header/html-coding.png"></img>
        </div>
        <div>
          {" "}
          <img src="images/header/motherboard.png"></img>{" "}
        </div>
        <div>
          {" "}
          <img src="images/header/globe.png"></img>
        </div>
        <div>
          {" "}
          <img src="images/header/cloud.png"></img>
        </div>
        <div>
          {" "}
          <img src="images/header/orditech.png"></img>
        </div>
        <div></div>
        <div></div>
        <div>
          {" "}
          <img src="images/header/base_donnee.png"></img>
        </div>
        <div>
          {" "}
          <img src="images/header/cercle.png"></img>
        </div>
      </div>
    </Fragment>
  );
};

export default Header;
