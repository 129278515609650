import React from 'react';
import {Grid } from '@mui/material';
import {  makeStyles } from '@mui/styles';
import { useTranslation } from "react-i18next";

import AwardData from '../../../../Data/Award.json';
import './award.scss';
import SectionComponent from '../../../produits/components/SectionComponent';


const useStyles = makeStyles({
    sectionAward: {
        maxWidth: "100%", 
        // border: `${alpha(theme.common.bodyDefault, 0.1)} solid 1px`, 
        // padding: theme.spacing(2, 2),
        borderRadius: "10px",
        "&:hover": {
            boxShadow:"0 10px 33px rgba(0,0,0,.1)!important",
        }
    }
});

const AwardContent = ({data, classes}) => {
    return(
        <Grid>
            <div className ="ag-container-shops">
                <div className ="js-flickity-slider" data-flickity='{ "autoPlay": true, "draggable": true, "prevNextButtons": false, "wrapAround": true, "pageDots": true}'>
                    {data.map(({ id, title, date, image, link }) => {
                        return (
                            <div className ="js-carousel-cell" key={id} >
                                <div className ="ag-shop-card_box-wrap">
                                    <div className ="ag-shop-card_box">
                                        <div className ="ag-shop-card_body">
                                            <div className ="js-card-bg ag-card-bg" style={{
                                                    backgroundImage: `url(${image})`,
                                                    backgroundSize: "cover",
                                                    backgroundPosition: "100%",
                                                }}
                                            ></div>
                                            <a  rel='noreferrer' href={`/blog/${id}`} className ="ag-shop-card-body_link"><img src={image} alt="Falu" className ="ag-shop-card-body_img"/></a>
                                        </div>
                                        <div className ="ag-shop-card_footer">
                                            <span className ="ag-shop-card-footer_title">{title}</span>
                                            <span className ="ag-shop-card-footer_products">{date}</span>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        )
                    })}
                </div>
            </div>
        </Grid>
    )
}

const Award = () => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    return (
        <div id="award">
            <SectionComponent 
                // bgcolor={theme.common.offWhite}
                title={t("award_title")}
                content = {
                    <AwardContent data={AwardData} classes={classes} />
                }
            />
        </div>
        
    )
}

export default Award;
