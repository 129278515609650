import React, { useState, useContext } from 'react';
import { makeStyles } from '@mui/styles';
import { Container, Pagination, Stack } from '@mui/material';
import BlogList from '../../../components/Blog/BlogList/index';
import HeaderBlog from '../components/header/HeaderBlog';
import SearchBar from '../../../components/Blog/SearchBar/index';
import { blogList_fr } from '../../../Data/Blog_fr';
import { blogList_en } from '../../../Data/Blog_en';
import LanguageContext from '../../../contexts/Language/LanguageContext';
import { useTranslation } from "react-i18next";
import './blog.scss';

const useStyles = makeStyles(() => ({
  ul: {
    "& .MuiPaginationItem-root.Mui-selected": {
      backgroundColor: "#2896af",
      color: "white",
      "&:hover": {
        backgroundColor: "#2896af"
      }
    }
  }
}));

const BlogDetail = () => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(6);

  const { activeLanguage } = useContext(LanguageContext);
  let blogList = activeLanguage === "Français" ? blogList_fr : blogList_en;
  let [blogs, setBlogs] = useState(blogList);
    // console.log("Active language", blogList);

  // change page
  const handleChangePage = (e, number) => {
    setPage(number-1);
  };

  // Search submit
  let searchKey = ""
  const handleSearchBar = (e) => {
    searchKey = e.target.value
    e.preventDefault();
    const allBlogs = blogList;
    const filteredBlogs = allBlogs.filter((blog) =>
      blog.category.toLowerCase().includes( searchKey.toLowerCase())
    );
    setBlogs(filteredBlogs);
  }; 



  // Clear search and show all blogs

  return (
    <div className='container'>
      {/* Page Header */}
      <HeaderBlog
        title={t("headerBlog_titre")}
        summary={t("headerBlog_description")}
      />

      {/* Search Bar */}
      <SearchBar formSubmit={handleSearchBar} />

      {/* Blog List & Empty View */}
      {blogs.length === 0 ? (
        <div className="emptyList-wrap">
          <img src="/images/Blog/removeEmpty.png" alt="empty" />
        </div>
      ) : (
        <BlogList
          blogs={blogList.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
          )}
        />
      )}

      {/* Pagination */}
      <Container>
        <Stack sx={{ alignItems: "center", padding: "5rem 0rem" }}>
          {blogs.length <= 6 ? (
            ""
          ) : (
            <Pagination
              variant="outlined"
              count={Math.ceil(blogs.length / 6)}
              shape="rounded"
              onChange={handleChangePage}
              classes={{ ul: classes.ul }}
            />
          )}
        </Stack>
      </Container>
    </div>
  );
};

export default BlogDetail;