import React from 'react';
import { Link } from 'react-router-dom';
import Chip from '../../Chip/index';
import { useTranslation } from "react-i18next";
import './styles.css';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

const BlogItem = ({blog}) => {
  const { t, i18n } = useTranslation();
  return (
    <div className='blogItem-wrap'>
      <img className='blogItem-cover' src={blog.cover} alt='cover' />
      <Chip className={"category"} label={blog.category} />
      <h3>{blog.title}</h3>
      <p className='blogItem-desc' dangerouslySetInnerHTML={{__html: blog.summaryDesc}}></p>
      <footer>
        <div className='blogItem-author'>
          <img src={blog.authorAvatar} alt='avatar' />
          <div>
            <h6>{blog.authorName}</h6>
            <p>{blog.createdAt}</p>
          </div>
        </div>
        <Link className='blogItem-link' to={`/blog/${blog.id}`}>
          <span>{t("blogItem_Button" )}</span>
          <ArrowRightAltIcon sx={{
            marginTop: "-4px",
            position: "absolute",
            transition:" 0.3s ease-in-out", 
            "&:hover":{
              transform: "translateX(5px)"
            }
          }}/>
        </Link>
      </footer>
    </div>
  );
};

export default BlogItem;