import React from "react";
import { Box } from "@mui/system";
import { Container } from "@mui/material";

const SectionComponent = ({ content }) => {
  return (
    <Container>
      <Box>{content}</Box>
    </Container>
  );
};

export default SectionComponent;
