import React from "react";
import Gallery from "../components/gallery/Gallery";
import Header from "../components/header/Header";
import Realisation from "../components/realisation/Realisation";
import Services from "../components/service/Services";


const Home = () => {
  return (
    <>
      <Header/>
      <Services />
      <Realisation />
      <Gallery />
    </>
  );
};

export default Home;
