import React from "react";
import "./technology.scss";
import { useTranslation } from "react-i18next";
  

const Technology = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <div className="container">
        <div className="title">
          <h1>{t("Technologies we use")}</h1>
        </div>
        <div className="row">
          <div className="col-sm technology">
            <img
              className="technology-logo"
              src="images/technology/android-studio-logo.png"
            />
          </div>
          <div className="col-sm technology">
            <img
              className="technology-logo"
              src="images/technology/angular_src_logo.png"
            />
          </div>
          <div className="col-sm technology">
            <img
              className="technology-logo"
              src="images/technology/react_original_wordmark_logo.png"
            />
          </div>
          <div className="col-sm technology">
            <img
              className="technology-logo"
              src="images/technology/laravel_logo.png"
            />
          </div>
          <div className="col-sm technology">
            <img
              className="technology-logo"
              src="images/technology/django-logo.png"
            />
          </div>
          <div className="col-sm technology">
            <img
              className="technology-logo"
              src="images/technology/flask-logo.png"
            />
          </div>
          <div className="col-sm technology">
            <img
              className="technology-logo"
              src="images/technology/spring_logo.png"
            />
          </div>
          <div className="col-sm technology">
            <img
              className="technology-logo"
              src="images/technology/spring-boot-logo.png"
            />
          </div>
          <div className="col-sm technology">
            <img
              className="technology-logo"
              src="images/technology/node.js-logo.svg"
            />
          </div>
          <div className="col-sm technology">
            <img
              className="technology-logo"
              src="images/technology/material_ui_icon.png"
            />
          </div>
          <div className="col-sm technology">
            <img
              className="technology-logo"
              src="images/technology/bootstrap_plain_wordmark_logo.png"
            />
          </div>
          <div className="col-sm technology">
            <img
              className="technology-logo"
              src="images/technology/python_logo.png"
            />
          </div>
          <div className="col-sm technology">
            <img
              className="technology-logo"
              src="images/technology/file_type_php.png"
            />
          </div>
          <div className="col-sm technology">
            <img
              className="technology-logo"
              src="images/technology/java_original_wordmark_logo.png"
            />
          </div>
          <div className="col-sm technology">
            <img
              className="technology-logo"
              src="images/technology/javascript_original_wordmark_logo.png"
            />
          </div>
          <div className="col-sm technology">
            <img
              className="technology-logo"
              src="images/technology/html_original_wordmark_logo.png"
            />
          </div>
          <div className="col-sm technology">
            <img
              className="technology-logo"
              src="images/technology/css_original_wordmark_logo.png"
            />
          </div>
          <div className="col-sm technology">
            <img
              className="technology-logo"
              src="images/technology/sass_logo.png"
            />
          </div>
          <div className="col-sm technology">
            <img
              className="technology-logo"
              src="images/technology/mimetypes_cppsource_logo.png"
            />
          </div>
          <div className="col-sm technology">
            <img
              className="technology-logo"
              src="images/technology/c_logo.png"
            />
          </div>
          <div className="col-sm technology">
            <img
              className="technology-logo"
              src="images/technology/git_original_wordmark_logo.png"
            />
          </div>
          <div className="col-sm technology">
            <img
              className="technology-logo"
              src="images/technology/mysql_original_wordmark_logo.png"
            />
          </div>
          <div className="col-sm technology">
            <img
              className="technology-logo"
              src="images/technology/postgresql_original_wordmark_logo.png"
            />
          </div>
          <div className="col-sm technology">
            <img
              className="technology-logo"
              src="images/technology/mongodb_logo.png"
            />
          </div>
          <div className="col-sm technology">
            <img
              className="technology-logo"
              src="images/technology/firebase-logo.png"
            />
          </div>
          <div className="col-sm technology">
            <img
              className="technology-logo"
              src="images/technology/file_type_photoshop.png"
            />
          </div>
          <div className="col-sm technology">
            <img
              className="technology-logo"
              src="images/technology/file_type_ai_icon.png"
            />
          </div>
          <div className="col-sm technology">
            <img
              className="technology-logo"
              src="images/technology/autodesk-logo.png"
            />
          </div>
          <div className="col-sm technology">
            <img
              className="technology-logo"
              src="images/technology/freertos-logo.png"
            />
          </div>
          <div className="col-sm technology">
            <img
              className="technology-logo"
              src="images/technology/arm-1.jpg"
            />
          </div>
          <div className="col-sm technology">
            <img
              className="technology-logo"
              src="images/technology/Logo-Segger.png"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Technology;
