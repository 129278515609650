import React from "react";
import Slider from "infinite-react-carousel";
import "./carousel.scss";
import CarouselItem from "./carouselItem/CarouselItem";

const Carousel = ({ title, items, slidesToShow, autoplaySpeed }) => {
  return (
    <>
      <h1 className="carouselTitle">{title}</h1>
      <div className="carouselContainer">
        <Slider
          slidesToShow={parseInt(slidesToShow)}
          autoplaySpeed={parseInt(autoplaySpeed)}
          autoplay
          arrows={false}
          centerMode
        >
          {items.map((item, index) => (
            <CarouselItem item={item} key={index} />
          ))}
        </Slider>
      </div>
    </>
  );
};

export default Carousel;
