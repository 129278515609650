export const blogList_fr = [
  {
    id: 1,
    title: "TIE CHAMPION ",
    category: "Compétition",
    subCategory: ["Santé", "Cameroun", "KmerTech"],
    description: `À la suite de notre candidature à la compétition TIE Champions organisée par la KmerTech en partenariat avec l'Union Européenne, Il nous a été permis de remporter le premier prix de la catégorie santé et faire la couverture du <a target="_blank" href="https://www.afriknews7.com/tie-champion-le-pitch-day-jour-de-verdict/">AfrikNews7</a>.`,
    summaryDesc:
      "À la suite de notre candidature à la compétition TIE Champions organisée par la KmerTech en partenariat avec l'Union Européenne, Il nous a été permis de remporter le premier prix de la catégorie santé et faire la couverture du AfrikNews7.",
    authorName: "Afrik News 7",
    authorAvatar: "/images/Blog/afrikNews7.jfif",
    createdAt: "March 04, 2022",
    cover: "/images/Blog/afrikNews7_Picture.jpg",
  },
  {
    id: 2,
    title: "TIE CHAMPION",
    category: "Compétition",
    subCategory: ["Santé", "Cameroun", "KmerTech"],
    description: `<a target="_blank" href="https://cameroonceo.com/2022/03/10/tie-champion-2022-la-kmerterch-a-tenu-le-pari-de-lorganisation/#google_vignette">Cameroon CEO</a> a parlé de nous à la suite de notre réussite à la compétition TIE Champions organisée par la KmerTech en partenariat avec l'Union Européenne. En effet, après un travail collectif entre notre équipe et les mentors du programme nous avons été désigné vainqueur du premier prix dans la catégorie santé.`,
    summaryDesc:
      "Cameroon CEO a parlé de nous à la suite de notre réussite à la compétition TIE Champions organisée par la KmerTech en partenariat avec l'Union Européenne. En effet, après un travail collectif entre notre équipe et les mentors du programme nous avons été désigné vainqueur du premier prix dans la catégorie santé.",
    authorName: "Cameroon CEO",
    authorAvatar: "/images/Blog/cameroonCEO.jpg",
    createdAt: "March 10, 2022",
    cover: "/images/Blog/cameroonCEO_Picture.jpg",
  },
  {
    id: 3,
    title: "Prix africain de l'innovation en ingénierie",
    category: "Compétition",
    subCategory: ["Santé", "Afrique", "Royal Academy of Engineering"],
    description: `Le Prix africain a présélectionné 16 innovateurs qui favorisent le développement grâce à la créativité des ingénieurs. À l'occasion de notre sélection dans cette liste, notre solution TelMi a été mentionnée dans <a target="_blank" href="https://www.globaldiasporanews.com/great-minds-who-won-the-2022-african-prize-for-engineering-innovation/">GD News</a>.`,
    summaryDesc:
      "Le Prix africain a présélectionné 16 innovateurs qui favorisent le développement grâce à la créativité des ingénieurs. À l'occasion de notre sélection dans cette liste, notre solution TelMi a été mentionnée dans GD News.",
    authorName: "Global Diaspora News",
    authorAvatar: "/images/Blog/GDNews.png",
    createdAt: "February 2, 2022",
    cover: "/images/Blog/GDNews_Picture.JPG",
  },
  {
    id: 4,
    title: "Prix africain de l'innovation en ingénierie",
    category: "Compétition",
    subCategory: ["Santé", "Afrique", "Royal Academy of Engineering"],
    description: `<blockquote>
          <p><em>Le Prix Afrique sélectionne une liste restreinte des innovateurs, qui stimulent le développement grâce à l’ingéniosité de l’ingénierie.</em></p>
         </blockquote>
         
         Nous avons fait partie de cette liste pour la 8ème édition de 2022. Cela nous a valu de faire la une de certains magazines comme <a target="_blank" href="https://www.cameroun24.net/actualite-cameroun-Deux_camerounais_selectionnes_pour_le_prix_Africa_-1-1-58737.html">Cameroun24</a>.`,
    summaryDesc:
      "Le Prix Afrique sélectionne une liste restreinte des innovateurs, qui stimulent le développement grâce à l’ingéniosité de l’ingénierie. Nous avons fait partie de cette liste pour la 8ème édition de 2022. Cela nous a valu de faire la une de certains magazines comme Cameroun24.",
    authorName: "Cameroun24",
    authorAvatar: "/images/Blog/cameroun24.jfif",
    createdAt: "January 29, 2022",
    cover: "/images/Blog/cameroun24_Picture.jpg",
  },
  {
    id: 5,
    title: "Fabrice Tueche, de la passion à l’action",
    category: "Interview",
    subCategory: ["Santé", "Afrique", "Royal Academy of Engineering"],
    description: `En observant ceux qui connaissent le succès, on remarque très vite que ce sont des personnes qui ont beaucoup de traits en commun. L’une de ces choses c’est  <strong>la passion </strong>. 
    Le monde entier est rempli de personnes qui ont du succès et qui ont réussi, l’Afrique n’en est pas des restes; cette nouvelle génération de <strong> « disruptors » </strong>, se veut parmi ceux qui changent le monde,  <strong> leur monde  </strong> en particulier et ceci non seulement afin de déposer leur empreinte sur le sable du temps, mais aussi pour laisser un héritage qui aidera à améliorer les vies des personnes qu’ils veulent impacter à tout jamais.`,
    summaryDesc:
      "Je pense que nous avons une jeunesse riche de savoir et que comme certains pays développés tel que l’Allemagne, nous devons fonder nos richesses sur le savoir et les compétences de notre population plutôt que sur des ressources naturelles.",
    authorName: "Jeunesse du Mboa",
    authorAvatar: "/images/Blog/jeunessedumboaavatar.png",
    createdAt: "June 29, 2022",
    cover: "/images/Blog/jeunessedumboa.jpg",
  },
];