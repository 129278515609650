import React from "react";
import "../carousel.scss";

const CarouselItem = ({ item }) => {
  return (
    <div className="carouselItem">
      <img src={item.source} alt="" />
    </div>
  );
};

export default CarouselItem;
