import styled from 'styled-components';

export const Column = styled.div`
vertical-align: top
  max-size: 1000px;
  margin: auto;
  z-index: 100;
`;

export const Heading = styled.p`
  position: relative;
  font-size: 24px;
  line-height: 30px;
  color: var(--input_color);
  margin-bottom: 15px;
  font-weight: bold;
  
`;


export const FooterLink = styled.a`
  color: var(--color_footer_link);
  margin-bottom: 8px;
  font-size: 17px;
  text-decoration: none;

  &:hover {
    color: rgb(40, 150, 175);
    transition: 300ms ease-in-out;
  }
`;