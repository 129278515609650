import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { blogList_fr } from '../../../Data/Blog_fr';
import { blogList_en } from '../../../Data/Blog_en';
import LanguageContext from '../../../contexts/Language/LanguageContext';
import Chip from '../../../components/Blog/Chip/index';
import HeaderBlog from '../components/header/HeaderBlog';
import './blog.scss';
import { useTranslation } from "react-i18next";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';


const Blog = () => {
  const { id } = useParams();
 const { activeLanguage } = useContext(LanguageContext);
 let blogList = activeLanguage === "Français" ? blogList_fr : blogList_en;
  let [blog, setBlog] = useState(null);

  useEffect(() => {
    let blog = blogList.find((blog) => blog.id === parseInt(id));
    if (blog) {
      setBlog(blog);
    }
  }, []);
  const { t, i18n } = useTranslation();
  return (
    <>
      <HeaderBlog
        title={t("headerBlog_titre")}
        summary={t("headerBlog_description")}
      />

      {blog ? (
        <div className="blog-wrap">
          <header>
            <p className="blog-date">
              {t("headerBlog_createdAt")} {blog.createdAt}
            </p>
            <h1>{blog.title}</h1>
            <div className="blog-subCategory">
              {blog.subCategory.map((category, i) => (
                <div key={i}>
                  <Chip className={"chip"} label={category} />
                </div>
              ))}
            </div>
          </header>
          <img src={blog.cover} alt="cover" />
          <div
            className="blog-desc"
            dangerouslySetInnerHTML={{ __html: blog.description }}
          ></div>
          <Link className="blog-goBack" to="/blog">
            {/* <span> &#8592;</span>  */}
            <span>
              <ArrowRightAltIcon
                sx={{
                  marginTop: "-2px",
                  position: "absolute",
                  transform: "rotate(-180deg)",
                  marginLeft: "-25px",
                }}
              />
              {t("headerBlog_BoutonAllArticle")}{" "}
            </span>
          </Link>
        </div>
      ) : (
        <div className="emptyList-wrap">
          <img src="/images/Blog/removeEmpty.png" alt="empty" />
        </div>
      )}
    </>
  );
};

export default Blog;
