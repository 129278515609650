import React, { useContext } from "react";
import { serviceDetail_fr } from '../../../Data/Service_fr';
import HeaderServices from "../components/header/HeaderServices";
import './service.scss';
import LanguageContext from '../../../contexts/Language/LanguageContext';
import ServiceDetailList from "./ServiceDetailList";
import { serviceDetail_en } from "../../../Data/Service_en";
import Technology from "../components/technology/Technology";



const Services = () => {
  const { activeLanguage } = useContext(LanguageContext);
  let serviceDetail = activeLanguage === "Français" ? serviceDetail_fr : serviceDetail_en;
  return( 
    <>
      <HeaderServices/>
      <ServiceDetailList services={serviceDetail}/>
      <Technology/>
    </>
  );
};

export default Services;
