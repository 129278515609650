import React from "react";
import { Link } from "react-router-dom";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import "./services.scss";
import { useTranslation } from "react-i18next";

const Services = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="accueil-service content">
      <h1>{t("headerServices_titre")}</h1>
      <div className="row-accueil">
        <div className="column-accueil " style={{ position: "relative" }}>
          <img
            className="image-dev"
            src={"images/servicePrograming.png"}
            alt="Problems and visions image 1"
          />
        </div>
        <div className="column-accueil text-accueil">
          <h2>{t("services1_accueil")}</h2>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <p >{t("services_description1")}</p>
          </div>

          <Link className="plus_service" to="/services">
            <button>
              {t("Read more")}
              <ArrowRightAltIcon
                sx={{
                  marginTop: "-4px",
                  position: "absolute",
                  marginLeft: "5px",
                }}
              />
            </button>
          </Link>
        </div>
      </div>
      <div className="row-accueil row2">
        <div className="column-accueil text-accueil texte2">
          <h2>{t("services2_accueil")}</h2>
          <p>{t("services_description2")}</p>

          <Link className="plus_service btn2" to="/services">
            <button>
              {t("Read more")}
              <ArrowRightAltIcon
                sx={{
                  marginTop: "-4px",
                  position: "absolute",
                  marginLeft: "5px",
                }}
              />
            </button>
          </Link>
        </div>
        <div
          className="column-accueil ligne2"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <img
            className="image-logo-design"
            src={"images/designer.png"}
            alt="image logo/design"
          />
        </div>
      </div>
      <div className="row-accueil">
        <div className="column-accueil img3" style={{ position: "relative" }}>
          <img
            className="image-conception"
            src={"images/appDesktop.png"}
            alt="image conception electronique"
          />
        </div>
        <div className="column-accueil text-accueil texte3">
          <h2>{t("services3_accueil")}</h2>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <p>{t("services_description3")}</p>
          </div>

          <Link className="plus_service" to="/services">
            <button>
              {t("Read more")}
              <ArrowRightAltIcon
                sx={{
                  marginTop: "-4px",
                  position: "absolute",
                  marginLeft: "5px",
                }}
              />
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Services;
