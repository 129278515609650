import React from "react";
import ContactContent from "../components/contactContent/ContactContent";
import HeaderContact from "../components/header/HeaderContact";
import { useTranslation } from "react-i18next";

const Contact = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <HeaderContact
        title={t('headerContact_titre')}
        summary={t('headerContact_description')}
        />
      <div style={{ padding: "5rem 0rem" }}>
        <ContactContent />
      </div>
    </>
  );
};

export default Contact;
