import { Box } from "@mui/system";
import React from "react";
import './headerServices.scss';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { useTranslation } from "react-i18next";



const HeaderServices = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="service-header">
        <Box
          sx={{
            background: `linear-gradient(to top, var(--background_contact), var(--background_contact_linear))`,
            backgroundPosition: "bottom",
            backgroundPositionX: "90%",
            height: "400px",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "@media(min-width:1600px)":{
              backgroundSize: "600px",
              backgroundPosition: "bottom",
              backgroundPositionX: "82%",
            } ,
            "@media(max-width:1200px)":{
              background: `linear-gradient(to top, var(--background_contact), var(--background_contact_linear))`,
              height: "300px"
            } ,
          }}
          >
          
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >


            <div className="box-img">
                <div> <img src="images/header/header_services/orditech.png"></img></div>
                <div> <img src="images/header/header_services/html-coding.png"></img> </div>
                <div> <img src="images/header/header_services/electronic.png"/></div>
                <div> <img src="images/header/header_services/circuits.png"/></div>
            </div>


            <Box
              sx={{
                fontWeight: 700,
                "@media(max-width:960px)":{
                  marginTop: "60px"
                },
                "@media screen and (min-width: 1200px)":{
                  marginTop: "60px"
                }
              }}
              >
              <h1 style={{color: "#2896af"}}>
                <span></span> {t('headerServices_titre')} <span></span>
              </h1>
            </Box>
            <Breadcrumbs aria-label="breadcrumb" style={{margin: "12px"}}>
            <Link underline="hover" color="var(--theme_outline_dark)" fontWeight="bold" href="/" sx={{textDecoration: "none"}}
            >
                {t('Accueil')}
            </Link>
            <Typography color="text.primary" style={{fontWeight: "bold"}}>{t('Services')}</Typography>
        </Breadcrumbs>
          </Box>
        </Box>

        

      </div>

  );
};

export default HeaderServices;
