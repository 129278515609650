import React from "react";
import { useTranslation } from "react-i18next";
import "./product.scss";

const Products = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <div className="container product">
        <h1>TELMI</h1>
        <div className="description-text">{t("description_product")}</div>
        <h2>{t("button_call")}</h2>
        {/* Button call */}
        <div className="row">
          <div className="col-sm-6">
            <img
              className="image-button"
              width={"100%"}
              src={"images/Product/boutondappel.png"}
              alt="image"
            />
          </div>
          <div className="col-sm-6 col-description">
            <div className="ul">
              <ul>
                <li>{t("Early warning to staff")}</li>
                <li>{t("Easy to use")}</li>
                <li>{t("Wireless, no internet connection")}</li>
                <li>{t("Easy to install")}</li>
                <li>{t("Very long autonomy (several months)")}</li>
              </ul>
            </div>
            <div className="produit-table-right">
              <table className="styled-table">
                <thead>
                  <tr>
                    <th colSpan="2">{t("Product characteristics")}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{t("Product characteristics")}</td>
                    <td>TM-B01</td>
                  </tr>
                  <tr>
                    <td>{t("Dimension")}</td>
                    <td>6.5x6.5x1.5CM</td>
                  </tr>
                  <tr>
                    <td>{t("Weight")}</td>
                    <td>50g</td>
                  </tr>
                  <tr>
                    <td>{t("Battery")}</td>
                    <td>23AE 12V</td>
                  </tr>
                  <tr>
                    <td>{t("Battery life")}</td>
                    <td>≥ 10000H</td>
                  </tr>
                  <tr>
                    <td>{t("Transmission frequency")}</td>
                    <td>433.500MHZ</td>
                  </tr>
                  <tr>
                    <td>{t("Warranty")}</td>
                    <td>12 {t("Months")}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/*Watch */}
        <h2>{t("Watch TM-M01")}</h2>
        <div className="row">
          <div className="col-sm-6">
            <img
              className="image-watch"
              src={"images/Product/montre-TM-M01.png"}
              width={"100%"}
              alt="image logo/design"
            />
          </div>
          <div className="col-sm-6 col-description">
            <div className="ul">
              <ul>
                <li>{t("Portable")}</li>
                <li>{t("Instant reception of alerts")}</li>
                <li>{t("Sound and vibration alarm")}</li>
                <li>{t("Customised configurations")}</li>
              </ul>
            </div>
            <div className="produit-table-right">
              <table className="styled-table">
                <thead>
                  <tr>
                    <th colSpan="2"> {t("Product characteristics")}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{t("Name")}</td>
                    <td>TM – M01</td>
                  </tr>
                  <tr>
                    <td>{t("Dimension")}</td>
                    <td>4x2.5x0.7CM</td>
                  </tr>
                  <tr>
                    <td>{t("Weight")}</td>
                    <td>50g</td>
                  </tr>
                  <tr>
                    <td>{t("Alert")}</td>
                    <td>{t("Alert and sound")}</td>
                  </tr>
                  <tr>
                    <td>{t("Autonomy")}</td>
                    <td>120 {t("Hours")}</td>
                  </tr>
                  <tr>
                    <td>{t("Transmission frequency")}</td>
                    <td>433.500MHZ</td>
                  </tr>
                  <tr>
                    <td>{t("Warranty")}</td>
                    <td>12 {t("Months")}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/*Station */}
        <h2>{t("DISPLAY STATION TM-A0")}</h2>
        <div className="row">
          <div className="col-sm-6">
            <img
              className="image-station"
              width={"100%"}
              src={"images/Product/stationdaffichageTM-A0.png"}
              alt="image conception electronique"
            />
          </div>
          <div className="col-sm-6 col-description">
            <div className="ul">
              <ul>
                <li>{t("Receives messages from call buttons")}</li>
                <li>{t("Emits audible alarms")}</li>
                <li>{t("Easy to install")}</li>
                <li>{t("Low energy consumption")}</li>
              </ul>
            </div>
            <div className="produit-table-right">
              <table className="styled-table">
                <thead>
                  <tr>
                    <th colSpan="2">{t("BattProduct characteristicsery")}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{t("Name")}</td>
                    <td>TM – M01</td>
                  </tr>
                  <tr>
                    <td>{t("Dimension")}</td>
                    <td>4x2.5x0.7CM</td>
                  </tr>
                  <tr>
                    <td>{t("Weight")}</td>
                    <td>50g</td>
                  </tr>
                  <tr>
                    <td>{t("Alert")}</td>
                    <td>{t("Alert and sound")}</td>
                  </tr>
                  <tr>
                    <td>{t("Autonomy")}</td>
                    <td>120 {t("Hours")}</td>
                  </tr>
                  <tr>
                    <td>{t("Transmission frequency")}</td>
                    <td>433.500MHZ</td>
                  </tr>
                  <tr>
                    <td>{t("Warranty")}</td>
                    <td>12 {t("Months")}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div className="product">
        <div className="description-web-app">
          <h1>{t("TELMI'S WEB APPLICATION")}</h1>
          <div className="description-text">
            <img
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "auto",
              }}
              width={"100%"}
              src={"images/Product/web-application-telmi.png"}
              alt="image conception electronique"
            />
          </div>
          <div className="app-presenation">
            <div className="first-column">
              <h3>{t("What the application can do:")}</h3>
              <ul>
                <li>
                  {t("Display of outstanding alerts within the structure")}
                </li>
                <li>
                  {t(
                    "Display of alert statistics (by period, department or personnel)"
                  )}
                </li>
                <li>{t("Staff behaviour analysis")}</li>
                <li>{t("Management of physical devices")}</li>
                <li>
                  {t(
                    "Prediction of user needs (feature under development) development)"
                  )}
                </li>
              </ul>
            </div>
            <div className="second-column">
              <h3>{t("What good does it do?")}</h3>
              <ul>
                <li>{t("Professional management tool")}</li>
                <li>{t("Tool for raising awareness among professionals")}</li>
                <li>{t("Streamlining of procedures")}</li>
                <li> {t("Better quality of service")}</li>
                <li>
                  {t(
                    "Improved satisfaction and thus profitability profitability"
                  )}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Products;
