export const serviceDetail_fr = [
  {
    title: "Développement Web ",
    image: "images/Services/internet.png",
    contenu:
      "Une équipe d'expert pour vos projets web : nous sommes capables de concrétiser vos idées et de donner vie à vos projets avec des créations sur mesure telles que des applications web. Tous nos développements suivent les normes en vigueur tout en restant vigilants face aux évolutions émergentes.",
  },
  {
    title: "Développement Mobile ",
    image: "images/Services/app-development.png",
    contenu:
      "Une équipe d'expert pour développer des applications Mobile offrant des résultats commerciaux inégalés. Nous créons des applications Mobile riches en fonctionnalités, rentables et évolutives adaptées aux besoins de votre entreprise à l'aide d'une technologie et d'outils de pointe.",
  },
  {
    title: "Développement Desktop ",
    image: "images/Services/desktop.png",
    contenu:
      "À l'aire de la révolution d'Internet et des smartphones, certains cas d'usage ne peuvent être remplacés entièrement pas une application web ou mobile notre équipe d'expert est dans la capacité de vous accompagner dans le développement complet des applications Desktop.",
  },
  {
    title: "Conception électronique",
    image: "images/Services/electronic_icon.png",
    contenu:
      "Nos experts réalisent toute la phase de conception électronique d’un produit ou d’un système électronique embarqué : étude de faisabilité, élaboration des schémas électroniques, la conception assistée par ordinateur pour l'électronique du circuit imprimé (routage PCB) et la fabrication de la carte électronique.",
  },
  {
    title: "Logo/Design ",
    image: "images/Services/logo-design.png",
    contenu:
      "Vous avez besoin d'un logo, un flyer ou une carte de visite pour rehausser l'image de votre entreprise ; une conception 3D ou un simple design graphique, notre équipe d'expert est là pour vous.",
  },
];