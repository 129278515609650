
import i18n from "i18next";
import { initReactI18next } from "react-i18next";


// Importing translation files

import translationFR from "../../languages/en/en.json";
import translationEN from "../../languages/fr/fr.json";


//Creating object with the variables of imported translation files
const resources = {
    English: {
        translation: translationFR,
    },

    Français: {
        translation: translationEN,
    },
};

//i18N Initialization

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: localStorage.getItem("lang") || "Français", //default language
        keySeparator: false,
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;