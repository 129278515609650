import React, { useState, useEffect, useContext } from "react";
import "./realisation.scss";
import realisation_en from "../../../../Data/realisation_en";
import realisation_fr from "../../../../Data/realisation_fr";
import LanguageContext from "../../../../contexts/Language/LanguageContext";


const Realisation = () => {
  const { activeLanguage } = useContext(LanguageContext);
  let realisationDetail =
    activeLanguage === "Français" ? realisation_fr : realisation_en;

  const [count, setCount] = useState(0);

  const increment = function () {
    setCount((c) => c + 1);
  };

  useEffect(function () {
    const timer = window.setInterval(function () {
      increment();
    }, 20);

    return function () {
      clearInterval(timer);
    };
  }, []);

  return (
    <>
      <div className="realisation">
        <div className="element">
          {realisationDetail.map((item, index) => (
            <div className="element_realisation" key={index}>
              <h1 className="increment">
                {count < item.nombre ? count : item.nombre}
              </h1>
              <h2 className="desc_increment">{item.description}</h2>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Realisation;
