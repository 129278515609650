import React, { useContext } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbarv from "./components/navBar/Navbar";
import Home from "./pages/home/pages/Home";
import Blog from "./pages/blog/pages/Blog";
import Contact from "./pages/contact/pages/Contact";
import Footer from "./components/footer/Footer";
import ScrollButton from "./components/footer/ScrollButton";
import Services from "./pages/services/pages/Services";
import './styles/variables.scss';
import { ThemeContext } from "./styles/Theme";
import Produits from "./pages/produits/pages/Produits";
import BlogList from "./pages/blog/pages/BlogList";
import About from "./pages/about/pages/About";



function App() {
  const {theme} = useContext(ThemeContext);
  return (
    <div className={theme}>
      <BrowserRouter>
        <Navbarv />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/blog" element={<BlogList />} />
          <Route path="/blog/:id" element={<Blog />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/services" element={<Services />} />
          <Route path="/produits" element={<Produits />} />
          <Route path="/about" element={<About />} />
          <Route path="*" element={<Home />} />
        </Routes>
        {/* </IntlProvider> */}
        <ScrollButton />
        <Footer />
      </BrowserRouter>
    </div>
  );
}



export default App;
