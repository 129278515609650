import React from 'react';

import './styles.css';
import { useTranslation } from "react-i18next";

const SearchBar = ({ formSubmit}) => {
  const { t, i18n } = useTranslation();
  return(
    <div className='searchBar-wrap'>
      <form onSubmit={formSubmit}>
        <input
          type="search"
          placeholder={t("searchBarBlog_placeholder" )}
          onChange={formSubmit}

        />
        <button >{t("searchBarBlog_buttonSubmit")}</button>
      </form>
    </div>
  );
}

export default SearchBar;