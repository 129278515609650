let date_1 = new Date("05/25/2022");
let date_2 = new Date();

const days = (date_1, date_2) => {
  let difference = date_2.getTime() - date_1.getTime();
  let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
  return TotalDays;
};

const realisation = [
  {
    description: "Projets réalisés",
    nombre: 3,
  },

  {
    description: "Clients satisfaits",
    nombre: 20,
  },

  {
    description: "Jours de Travail",
    nombre: days(date_1, date_2),
  },

  {
    description: "Prix emportés",
    nombre: 2,
  },
];

export default realisation;
