const CarouselImg = [
  {
    source: "images/Services/Clinique_Vie1.jpg",
  },

  {
    source: "images/Services/cliniqueOuest.png",
  },

  {
    source: "images/Services/index.jpeg",
  },

  {
    source: "images/Services/Polyclinique_Bordeauxl.png",
  },
];

export default CarouselImg;
