import React from 'react';
import ServiceDetail from './ServiceDetail';
import './service.scss';

const ServiceDetailList = ({services}) => {
    return (
        <>
          <div className="row container">
            {services.map((title, index) => (
            <ServiceDetail item={title} key={index} />
            ))}
          </div>
        </>
    );
};

export default ServiceDetailList;