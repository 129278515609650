export const blogList_en = [
  {
    id: 1,
    title: "TIE CHAMPION ",
    category: "Challenge",
    subCategory: ["Health", "Cameroon", "KmerTech"],
    description: `As a result of our application to the TIE Champions competition organized by KmerTech in partnership with the European Union, we were awarded the first prize in the health category and made the cover of <a target="_blank" href="https://www.afriknews7.com/tie-champion-le-pitch-day-jour-de-verdict/">AfrikNews7</a>.`,
    summaryDesc:
      "As a result of our application to the TIE Champions competition organized by KmerTech in partnership with the European Union, we were awarded the first prize in the health category and made the cover of AfrikNews7.",
    authorName: "Afrik News 7",
    authorAvatar: "/images/Blog/afrikNews7.jfif",
    createdAt: "March 04, 2022",
    cover: "/images/Blog/afrikNews7_Picture.jpg",
  },
  {
    id: 2,
    title: "TIE CHAMPION",
    category: "Challenge",
    subCategory: ["Health", "Cameroon", "KmerTech"],
    description: `<a target="_blank" href="https://cameroonceo.com/2022/03/10/tie-champion-2022-la-kmerterch-a-tenu-le-pari-de-lorganisation/#google_vignette">Cameroon CEO</a> talked about us following our success in the TIE Champions competition organized by KmerTech in partnership with the European Union. Indeed, after a collective work between our team and the mentors of the program, we were designated winner of the first prize in the health category.`,
    summaryDesc:
      "Cameroon CEO talked about us following our success in the TIE Champions competition organized by KmerTech in partnership with the European Union. Indeed, after a collective work between our team and the mentors of the program, we were designated winner of the first prize in the health category.",
    authorName: "Cameroon CEO",
    authorAvatar: "/images/Blog/cameroonCEO.jpg",
    createdAt: "March 10, 2022",
    cover: "/images/Blog/cameroonCEO_Picture.jpg",
  },
  {
    id: 3,
    title: "Africa Price for Engineering Innovation",
    category: "Challenge",
    subCategory: ["Health", "Africa", "Royal Academy of Engineering"],
    description: `The Africa Prize has shortlisted 16 innovators who are driving development through engineering creativity. On the occasion of our selection in this list, our TelMi solution was mentioned in <a target="_blank" href="https://www.globaldiasporanews.com/great-minds-who-won-the-2022-african-prize-for-engineering-innovation/">GD News</a>.`,
    summaryDesc:
      "The Africa Prize has shortlisted 16 innovators who are driving development through engineering creativity. On the occasion of our selection in this list, our TelMi solution was mentioned in GD News.",
    authorName: "Global Diaspora News",
    authorAvatar: "/images/Blog/GDNews.png",
    createdAt: "February 2, 2022",
    cover: "/images/Blog/GDNews_Picture.JPG",
  },
  {
    id: 4,
    title: "Africa Price for Engineering Innovation",
    category: "Challenge",
    subCategory: ["Health", "Africa", "Royal Academy of Engineering"],
    description: `<blockquote>
          <p><em>The Africa Prize selects a shortlist of innovators who are driving development through engineering ingenuity.</em></p>
         </blockquote>
         
         We were part of this list for the 8th edition in 2022. This earned us a front page position in magazines such as <a target="_blank" href="https://www.cameroun24.net/actualite-cameroun-Deux_camerounais_selectionnes_pour_le_prix_Africa_-1-1-58737.html">Cameroun24</a>.`,
    summaryDesc:
      "The Africa Prize selects a shortlist of innovators who are driving development through engineering ingenuity. We were part of this list for the 8th edition in 2022. This earned us a front page position in magazines such as Cameroon24.",
    authorName: "Cameroun24",
    authorAvatar: "/images/Blog/cameroun24.jfif",
    createdAt: "January 29, 2022",
    cover: "/images/Blog/cameroun24_Picture.jpg",
  },
  {
    id: 5,
    title: "Fabrice Tueche, from passion to action",
    category: "Interview",
    subCategory: ["Health, Africa, Royal Academy of Engineering"],
    description: `By observing those who are successful, we quickly notice that they are people who have many traits in common. One of those things is <strong>passion</strong>.
    The whole world is full of successful and successful people, Africa is not a leftover; this new generation of <strong> "disruptors" </strong>, wants to be among those who change the world, <strong> their world </strong> in particular and this not only in order to leave their mark on the sand of time, but also to leave a legacy that will help improve the lives of the people they want to impact forever.`,
    summaryDesc:
      "JI think we have a knowledge-rich youth and like some developed countries such as Germany, we need to base our wealth on the knowledge and skills of our people rather than on natural resources.",
    authorName: "Jeunesse du Mboa",
    authorAvatar: "/images/Blog/jeunessedumboaavatar.png",
    createdAt: "June 29, 2022",
    cover: "/images/Blog/jeunessedumboa.jpg",
  },
];