import { React, useEffect } from "react";
import { useTranslation } from "react-i18next";

import './about.scss';
import HeaderPropos from '../components/header/HeaderPropos';
import ProblemsAndVisions from '../components/problemsAndVisions/ProblemsAndVisions';
import CarouselList from "../../../components/carousel/carouselList/CarouselList";
import Team from '../components/teams/Team';
import Award from '../components/award/Award';


const About = () => {
  useEffect(() => {
    const script1 = document.createElement("script");
    script1.src =
      "https://cdnjs.cloudflare.com/ajax/libs/jquery/3.4.1/jquery.min.js";
    script1.async = true;
    const script2 = document.createElement("script");
    script2.src = "https://npmcdn.com/flickity@2/dist/flickity.pkgd.js";
    script2.async = true;
    document.body.appendChild(script1);
    document.body.appendChild(script2);
  });
  const { t, i18n } = useTranslation();
  return (
    <div className="container">
      <HeaderPropos />
      <div className="container">
        <div className="row">
          <div className="col-sm">
            <img
              className="img-entreprise"
              src="images/Services/entrepriseService.png"
            />
          </div>
          <div className="col-sm col-text">
            <h2 className="award-descrip">
              <strong>{t("aboutus_title")} ?</strong>
            </h2>
            <p className="description">{t("aboutus_description")}</p>
          </div>
        </div>
      </div>
      <div>
        <ProblemsAndVisions />
      </div>
      <div className="teams">
        <h2> {t("team_titre")} </h2>
        <Team />
      </div>
      <div className="awards">
        <h2 className="award-descrip"> {t("award_title")} </h2>
        <Award />
      </div>
      <CarouselList />
    </div>
  );
};

export default About;