import React, { useContext } from "react";
import { Box } from "@mui/system";
import "./gallery.scss";
import SectionComponent from "../../../produits/components/SectionComponent";
import { useTranslation } from "react-i18next";
import GalleryData from "../../../../Data/Gallery";
import LanguageContext from "../../../../contexts/Language/LanguageContext";
import { Carousel } from "3d-react-carousal";

const Gallery = () => {
  const { t, i18n } = useTranslation();
  const { activeLanguage } = useContext(LanguageContext);
  return (
    <Box marginBottom={"50px"}>
      <Box>
        <h1 className="gallery_title">{t("gallery_title")}</h1>
        <SectionComponent
          content={
            <>
              <Carousel
                slides={GalleryData.map((item, index) => {
                  return (
                    <div className="carousel-gallery">
                      <img src={item.image} alt={item.id} key={index} />

                      <div className="text-wrap">
                        <div className="name">
                          <span>
                            {activeLanguage === "Français"
                              ? "Test en milieu hospitalier"
                              : "Test in a hospital environment"}
                          </span>
                        </div>
                        <div className="caption">
                          <p style={{ textAlign: "justify" }}>
                            {activeLanguage === "Français"
                              ? "Nous mettons les utilisateurs au centre de notre processus de développement."
                              : "We put users at the center of our development process."}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
                autoplay={true}
                interval={6000}
              />
            </>
          }
        />
      </Box>
    </Box>
  );
};

export default Gallery;
