const GalleryData = [
  {
    id: 1,
    image: "/images/Gallery/01.jpg",
  },
  {
    id: 2,
    image: "/images/Gallery/02.jpg",
  },
  {
    id: 3,
    image: "/images/Gallery/03.jpg",
  },
  {
    id: 4,
    image: "/images/Gallery/04.jpg",
  },
  {
    id: 5,
    image: "/images/Gallery/05.jpg",
  },
  {
    id: 6,
    image: "/images/Gallery/06.jpg",
  },
  {
    id: 7,
    image: "/images/Gallery/07.jpg",
  },
  {
    id: 8,
    image: "/images/Gallery/08.jpg",
  },
  {
    id: 9,
    image: "/images/Gallery/09.jpg",
  },
  {
    id: 10,
    image: "/images/Gallery/10.jpg",
  },
];

export default GalleryData;
