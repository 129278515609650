import React from 'react';
import { Box } from '@mui/system';
import { makeStyles } from '@mui/styles';
import {
    Typography,
    Grid,
    CardMedia,
    IconButton,
} from '@mui/material';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import MailIcon from '@mui/icons-material/Mail';
import SectionComponent from '../../../produits/components/SectionComponent';
import inHouseTeamData from '../../../../Data/inHouseTeam.json';
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  section: {
    maxWidth: "100%",
    borderRadius: "10px",
    padding: "25px",
    "&:hover": {
      marginTop: "-2px",
      transition: "0.2s linear",
      boxShadow: "0 10px 33px rgba(0,0,0,.1)!important",
    },
  },
  Icones: {
    "&.MuiIconButton-root": {
      margin: "2px",
      color: "var(--color_propos-text)",
      "&:hover": {
        transform: "translateY(-5px)",
        transition: "0.3s linear",
        color: "#008aa9",
      },
    },
  },
});

const Links = ({ link, children }) => {
    return (
        <a href={link} target="_blank" style={{ textDecoration: "none", color: "inherit" }}>
            {children}
        </a>
    );
};

const TeamContent = ({ data, classes, type }) => {
    return (
        <Grid container direction="row" justifyContent="center">
            {data.map(({ id, profile_image, fullname, post1, post2, linkeIn_link, gmail_address, phone }) => {
                return (
                  <React.Fragment key={id}>
                    <Grid>
                      <Box
                        elevation={0}
                        className={classes.section}
                      >
                        <Box
                          display="flex"
                          flexDirection="row"
                          justifyContent="center"
                          
                        >
                          <CardMedia
                            component="img"
                            image={profile_image}
                            alt={"Profile image"}
                            sx={{
                              "&.MuiCardMedia-root": {
                                borderRadius: "50%",
                                width: "120px",
                                height: "120px",
                              },
                            }}
                          />
                        </Box>
                        <Box>
                          <Box sx={{ textAlign: "center" }}>
                            <Typography
                              sx={{
                                fontSize: "17px",
                                color: "var(--color_propos-text)",
                              }}
                            >
                              {fullname}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              textAlign: "center",
                              marginTop: "8px",
                              minHeight: "35px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: "var(--color_propos-text)",
                              }}
                            >
                              {post1}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "12px",
                                color: "var(--color_propos-text)",
                              }}
                            >
                              {post2}
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          display="flex"
                          flexDirection="row"
                          justifyContent="center"
                        >
                          <Links link={linkeIn_link}>
                            <IconButton
                              color="inherit"
                              className={classes.Icones}
                            >
                              <LinkedInIcon
                                className={classes.IconeItem}
                                sx={{ fontSize: 15 }}
                              />
                            </IconButton>
                          </Links>
                          <Links link={`mailto:${gmail_address}`}>
                            <IconButton
                              color="inherit"
                              className={classes.Icones}
                            >
                              <MailIcon
                                className={classes.IconeItem}
                                sx={{ fontSize: 15 }}
                              />
                            </IconButton>
                          </Links>
                          {type === "interne" ? (
                            <Links link={`tel:+237${phone}`}>
                              <IconButton
                                color="inherit"
                                className={classes.Icones}
                              >
                                <LocalPhoneIcon
                                  className={classes.IconeItem}
                                  sx={{ fontSize: 15 }}
                                />
                              </IconButton>
                            </Links>
                          ) : null}
                        </Box>
                      </Box>
                    </Grid>
                  </React.Fragment>
                );
            })}
        </Grid>
    )
}

const Team = () => {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    return (
        <div id="team">
            <SectionComponent
                
                title={t( "team_InHouseTeam_titre" )}
                content={
                    <TeamContent data={inHouseTeamData} classes={classes} type="interne" />
                }
            />
        </div>

    )
}

export default Team;
