import React from "react";
import CarouselImg from "../../../Data/CarouselImg";
import Carousel from "../Carousel";
import { useTranslation } from "react-i18next";

const CarouselList = () => {
  let carouselList = CarouselImg;
  const { t, i18n } = useTranslation();
  return (
    <div style={{width:"90%"}}>
      <Carousel
        title={t("Customers")}
        items={carouselList}
        slidesToShow={"3"}
        autoplaySpeed={"2000"}
      />
    </div>
  );
};

export default CarouselList;
