import React, { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Menu, MenuItem } from "@mui/material";
import TranslateIcon from "@mui/icons-material/Translate";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LanguageContext from "../../contexts/Language/LanguageContext";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "../../styles/Theme";
import WbSunnyOutlinedIcon from '@mui/icons-material/WbSunnyOutlined';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import './navBar.scss';

const options = ["Français", "English"];

const NavBar = () => {


  const { t, i18n } = useTranslation();
  const { theme, toggleTheme } = useContext(ThemeContext);
  const [click, setClick] = useState(false);
  const [navbar, setNavabar] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  const { changeLanguage, activeLanguage } = useContext(LanguageContext);
  const [anchorEl, setAnchorEl] = useState(null);


  const handleClose = () => {
    setAnchorEl(null);
  };
  const [selectedIndex, setSelectedIndex] = useState(
    options.indexOf(activeLanguage)
  );
  
  const open = Boolean(anchorEl);
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    changeLanguage(options[index]);
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  const onscroll = () => {
    let element = document.querySelector('.navbar-container')
    let div = document.querySelector('.navbar')
    if (window.scrollY >= element.offsetTop) {
      div.classList.add('fixe')
    } 
    if(window.scrollY <= element.offsetTop) {
      div.classList.remove('fixe')
    }
  }
  document.addEventListener("scroll", onscroll)
  return (
    <nav className={"navbar"}>
      <div className="navbar-container">
        <NavLink to="/" className="navbar-logo" onClick={closeMobileMenu}>
          <img src="/logo1.png" alt="Logo de MesinTech" />
          <span>MesinTech</span>
        </NavLink>
        <div className="menu-icon" onClick={handleClick}>
          {click ? (
            <CloseIcon
              style={{ fontSize: "30px", color: "var(--color_navBar)" }}
            />
          ) : (
            <MenuIcon
              style={{ fontSize: "30px", color: "var(--color_navBar)" }}
            />
          )}
        </div>
        <ul id="items" className={click ? "nav-menu active" : "nav-menu"}>
          <li className="nav-item">
            <NavLink to="/" className="nav-links" onClick={closeMobileMenu}>
              {t("Accueil")}
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to="/services"
              className="nav-links"
              onClick={closeMobileMenu}
            >
              {t("Services")}
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to="/produits"
              className="nav-links"
              onClick={closeMobileMenu}
            >
              {t("Produits")}
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/blog" className="nav-links" onClick={closeMobileMenu}>
              {t("Blog")}
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to="/about"
              className="nav-links"
              onClick={closeMobileMenu}
            >
              {t("Propos")}
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to="/contact"
              className="nav-links"
              onClick={closeMobileMenu}
            >
              {t("Contact")}
            </NavLink>
          </li>
          <li className="nav-translate">
            <Button
              onClick={handleClickListItem}
              startIcon={<TranslateIcon style={{ fontSize: "15px" }} />}
              endIcon={<KeyboardArrowDownIcon />}
              sx={{
                "&.MuiButton-root": {
                  fontSize: "12px",
                  textTransform: "capitalize",
                  color: "var(--color_navBar)",
                  "&:hover": {
                    color: "#0096c7",
                    backgroundColor: "transparent",
                  },
                  "&:focus": {
                    outline: "#0096c7",
                  },
                },
              }}
            >
              <span>{options[selectedIndex]}</span>
            </Button>
            <Menu
              id="lock-menu"
              anchorEl={anchorEl}
              open={open}
              onClick={closeMobileMenu}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "lock-button",
                role: "listbox",
              }}
            >
              {options.map((option, index) => (
                <MenuItem
                  key={option}
                  selected={index === selectedIndex}
                  onClick={(event) => handleMenuItemClick(event, index)}
                >
                  {option}
                </MenuItem>
              ))}
            </Menu>
          </li>
        </ul>
        <div className="theme" onClick={() => toggleTheme()}>
          {theme === "light-theme" ? (
            <DarkModeOutlinedIcon
              className="dark"
              sx={{ fontSize: 20, color: "#008aa9" }}
            />
          ) : (
            <WbSunnyOutlinedIcon
              className="light"
              sx={{ fontSize: 20, color: "#008aa9" }}
            />
          )}
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
