import React from "react";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Container, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box, alpha } from "@mui/system";
import { Column, FooterLink, Heading } from "./FooterStyles";
import { useTranslation } from "react-i18next";
import './ScrollButtonStyles.scss';
import './footer.scss';

const useStyles = makeStyles({
  Icones: {
    "&.MuiIconButton-root": {
      marginRight: "5px",
      padding: "2px",
      border: `${alpha("#ffffff", 0.3)} 1px solid`,
      color: "#ffffff",
      "&:hover": {
        backgroundColor: "var(--theme_hover_light)",
        transition: "0.2s linear",
      },
    },
  },
});

const Links = ({ link, children }) => {
  return (
    <a
      href={link}
      style={{ textDecoration: "none", color: "inherit" }}
    >
      {children}
    </a>
  );
};

const Footer = () => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const year = new Date();
  return (
    <Box
      sx={{
        position: "relative",
        bottom: "0",
        top: "100%",
        width: "100%",
        background:
          "linear-gradient(to bottom, rgba(40, 150, 175, 0.2), rgba(214, 214, 214, 0.112) )",
      }}
    >
      
      <Container>
        <Box>
          <div className="row">
            <div className="footer-col ">
              <Column>
                <Heading> {t("Contact")} </Heading>
                <ul>
                  <li>
                    <FooterLink
                      href="tel:690985386"
                      style={{ cursor: "pointer" }}
                    >
                      <LocalPhoneIcon sx={{ fontSize: 20, color: "#2896af" }} />
                      (+237) 656-145-877
                    </FooterLink>
                  </li>
                  <li>
                    <FooterLink
                      href="mailto:info@mesintech.net"
                      style={{ cursor: "pointer" }}
                    >
                      <MailOutlineIcon
                        sx={{ fontSize: 20, color: "#2896af" }}
                      />
                      info@mesintech.net
                    </FooterLink>
                  </li>
                  <li>
                    <FooterLink style={{ cursor: "pointer" }}>
                      <FmdGoodIcon sx={{ fontSize: 20, color: "#2896af" }} />{" "}
                      Immeuble KAMGA, P8, Douala
                    </FooterLink>
                  </li>
                </ul>
              </Column>
            </div>
            <div className="footer-col">
              <Column>
                <Heading> {t("footer_title")} </Heading>
                <ul>
                  <li>
                    <FooterLink href="/services">
                      {t("footer_link_services1")}
                    </FooterLink>
                  </li>
                  <li>
                    <FooterLink href="/services">
                      {t("footer_link_services2")}
                    </FooterLink>
                  </li>
                  <li>
                    <FooterLink href="/services">
                      {t("footer_link_services3")}
                    </FooterLink>
                  </li>
                  <li>
                    <FooterLink href="/services">
                      {t("footer_link_services4")}
                    </FooterLink>
                  </li>
                  <li>
                    <FooterLink href="/services">
                      {t("footer_link_services5")}
                    </FooterLink>
                  </li>
                </ul>
              </Column>
            </div>
            <div className="footer-col">
              <Heading>{t("footer_title1")}</Heading>
              <Column>
                <ul>
                  <li>
                    <FooterLink href="/"> {t("Accueil")} </FooterLink>
                  </li>
                  <li>
                    <FooterLink href="/services"> {t("Services")} </FooterLink>
                  </li>
                  <li>
                    <FooterLink href="/produits"> {t("Produits")} </FooterLink>
                  </li>
                  <li>
                    <FooterLink href="/blog"> {t("Blog")}</FooterLink>
                  </li>
                  <li>
                    <FooterLink href="/propos"> {t("Propos")}</FooterLink>
                  </li>
                  <li>
                    <FooterLink href="/contact"> {t("Contact")}</FooterLink>
                  </li>
                </ul>
              </Column>
            </div>
          </div>
        </Box>
        <Box
          sx={{
            alignSelf: "center",
            marginTop: "40px",
            borderTop: ".1px  solid #D0D3D4",
          }}
        >
          <Box
            sx={{
              color: "var(--color_footer_link)",
              textAlign: "right",
              padding: "10px",
            }}
          >
            © {year.getFullYear()} MesinTech.
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="left"
            marginTop="-30px"
          >
            <Links link={`tel:+237690985386`}>
              <IconButton className={classes.Icones}>
                <FacebookIcon
                  className={classes.IconeItem}
                  id="icon"
                  sx={{ fontSize: 25, color: "#1f62f4f6" }}
                />
              </IconButton>
            </Links>
            <Links link={`https://www.linkedin.com/company/mesintech`}>
              <IconButton className={classes.Icones}>
                <LinkedInIcon
                  className={classes.IconeItem}
                  id="icon2"
                  sx={{ fontSize: 25, color: "#1f62f4f6" }}
                />
              </IconButton>
            </Links>
            <Links link={`https://twitter.com/MesinTech`}>
              <IconButton className={classes.Icones}>
                <TwitterIcon
                  className={classes.IconeItem}
                  id="icon3"
                  sx={{ fontSize: 25, color: "#3498DB" }}
                />
              </IconButton>
            </Links>
            <Links
              link={`https://www.youtube.com/channel/UCPHH9oJHapG2KpPoU3pQ3wA`}
            >
              <IconButton className={classes.Icones}>
                <YouTubeIcon
                  className={classes.IconeItem}
                  id="icon4"
                  sx={{ fontSize: 25, color: "#CB4335" }}
                />
              </IconButton>
            </Links>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
